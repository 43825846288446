<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O núcleo urbano de Paracatu de Baixo apresenta conformação
                longitudinal, acompanhando o curso do Rio Gualaxo do Norte. O
                conjunto urbano situa-se na margem sul do rio, enquanto os
                terrenos rurais encontram-se principalmente na margem norte,
                havendo também sítios e fazendas na margem sul a leste da
                comunidade, acompanhando a estrada que conduz a Pedras e
                Campinas. Em grande parte, estende-se por área de planície
                seguida de serra coberta por mata nativa.
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O conjunto diverge da forma de ocupação dos núcleos coloniais,
                nos quais as casas definem o arruamento, estando naqueles casos,
                dispostas no alinhamento das vias. Os terrenos de Paracatu de
                Baixo em sua maioria possuem grandes dimensões, com usos e
                ocupações aos moldes de regiões rurais, onde os espaços de
                cultivo e criação de animais prevalecem, gerando pouco
                adensamento. As casas foram implantadas de maneira recuada em
                relação às divisas, assim como as construções de uso rural a
                exemplo de galinheiros, chiqueiros, currais, paióis, entre
                outras. A comunidade era caracterizada pela presença de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_casas_jardins_quintais' }"
                  >quintais com hortas</router-link
                >, pomares e criação de animais, sendo a porção frontal dos
                lotes adornada com jardins floridos, e a paisagem contornada por
                áreas de mata.
              </p>
              <p>
                A porção de Paracatu de Baixo com maior número de edificações
                refere-se ao entorno da Igreja, principalmente a leste, aos pés
                da serra. Entretanto, seguindo a noroeste também eram
                identificadas terras com concentração de casas e construções
                acessórias, visto que, seguindo um costume comum ao subdistrito,
                várias organizações familiares compartilhavam um mesmo terreno.
                Era bastante comum os pais doarem uma gleba (parcela de terras)
                aos filhos conforme fossem constituindo suas próprias famílias.
                Também existiam várias situações em que os herdeiros
                compartilhavam as mesmas terras diante do falecimento de seus
                pais.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista parcial de Paracatu de Baixo a partir da área de planície,
            depois de ser atingida pela lama. Observa-se em segundo plano os
            terrenos da Rua Furquim, situados em cotas mais elevadas, com
            predomínio de áreas verdes e cultiváveis. Foto: Flora Passos, 2018.
          </p>

          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Horta cultivada em um quintal da comunidade de Paracatu de Baixo.
            Foto: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da área de planície de Paracatu de Baixo após o rompimento,
            tendo ao centro a Igreja de Santo Antônio, à frente dela a escola
            municipal, a creche. Foto: Leonardo Silva, 2021.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O traçado das ruas de Paracatu de Baixo é ordenado pela praça de
                Santo Antônio, situada na porção central da comunidade, para a
                qual se volta a norte a Igreja em louvor ao Santo. A praça, em
                formato triangular, abrange ainda a quadra poliesportiva e a
                escola municipal. A oeste do logradouro situa-se o prédio que
                abrigava a creche e a noroeste o posto de saúde, na mesma face
                da quadra da Igreja.
              </p>
              <p>
                Dos vértices da praça parte a Rua Furquim no sentido sul (que
                conduz ao distrito de mesma denominação) e a Rua Gualaxo no
                sentido leste (caminho para Pedras). A noroeste, segue a Rua
                Monsenhor Horta (caminho para esse distrito) e a nordeste,
                encontra-se uma rua sem saída para acesso de terrenos locais.
                Somente na década de noventa do século XX, as ruas, que eram de
                terra, receberam pavimentação asfáltica.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Além das construções de uso público mencionadas, ressalta-se o
                cemitério implantado em local de cotas elevadas, a sul do
                território, na bifurcação entre a Rua Furquim e a Rua Gualaxo.
                Também como ponto de referência e elemento de importância
                simbólica local é o cruzeiro situado em local elevado e de
                grande visibilidade, a sudoeste do subdistrito, em terreno com
                acesso pela Rua Furquim.
              </p>
              <p>
                Em relação aos usos, a predominância das edificações era
                residencial, havendo uma igreja católica (a Igreja de Santo
                Antônio), uma igreja evangélica, a venda do Carlinho, bares,
                entre eles o Bar do João Banana e Bar do Jairo. O núcleo urbano
                de Paracatu de Baixo apresentava uma forma de ocupação
                harmônica, onde predominavam edificações com um pavimento, sendo
                que apenas o prédio da escola e o bar do Jairo tinham gabarito
                de dois pavimentos. A Igreja de Santo Antônio e o ginásio
                apresentam pé-direito duplo. Cabe observar que a implantação do
                prédio da escola na Praça de Santo Antônio e a instalação de
                cobertura na quadra poliesportiva, ambos elementos de grandes
                proporções volumétricas, interferiram na estrutura urbana
                peculiar do subdistrito de Paracatu de Baixo, na qual o templo
                religioso se destacava e juntamente com a praça frontal era o
                ponto focal e organizava a disposição dos lotes e casas.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_04A.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista noturna de uma casa da comunidade de Paracatu de Baixo.
            Eduardo Moreira e Larissa Pinto/Jornal A Sirene, 2018.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da Igreja de Santo Antônio antes do rompimento da barragem.
            Foto: Autor e data não identificados.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_05.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista a partir da Praça Santo Antônio depois da destruição da
            comunidade pela lama, Observa-se a quadra esportiva em primeiro
            plano e a escola municipal em segundo plano. À direita da imagem,
            aos fundos e em ponto elevado, encontra-se o cemitério, ao fundo.
            Foto: Flora Passos, 2019.
          </p>

          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_06.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da Igreja de Santo Antônio depois da destruição da comunidade
            pela lama. Foto: Flora Passos, 2018.
          </p>

          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_07.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista parcial de Paracatu de Baixo depois da passagem do rejeito. À
            esquerda da imagem, as ruínas da escola municipal. Foto: Flora
            Passos, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                No arruamento de Paracatu de Baixo aconteciam as
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >festas religiosas
                </router-link>
                como a Festa de Santo Antônio e a Festa do Menino Jesus,
                organizadas pelos moradores com participação de visitantes de
                outras localidades da região. A tradicional
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade' }"
                  >Folia de Reis do Menino Jesus</router-link
                >, que ocorria desde 1954, é considerada patrimônio imaterial
                catalogado no inventário de Mariana e cadastrada pelo Instituto
                Estadual do Patrimônio Histórico e Artístico de Minas Gerais
                (IEPHA-MG), integrando o Dossiê para registro das Folias de
                Minas. O festejo começava sempre no dia 26 de dezembro e ia até
                o dia 6 de janeiro.
              </p>
              <p>
                Com a destruição da comunidade pela lama tóxica, os moradores
                foram forçados a se mudarem para outros locais, sendo a maioria
                deslocada para a sede de Mariana. Diante disso, ficaram
                impossibilitados de desenvolverem atividades produtivas, de
                cultivarem seus alimentos nos quintais, de realizarem trocas com
                os vizinhos, além de perderem o modo de viver tradicionalmente
                ocorrido por séculos naquele território. Ficaram ainda
                prejudicadas as manifestações culturais que, após o desastre,
                encontram muita dificuldade para serem reproduzidas da mesma
                forma como anteriormente ou até mesmo deixaram de ocorrer.
                Depois da destruição ocasionada pela ação da lama nas áreas de
                planície da comunidade, parte dos moradores permaneceram em seus
                terrenos, somando oito pessoas. Os terrenos concentram-se
                principalmente na Rua Furquim, que apresenta cotas mais
                elevadas. Ainda cuidando e evitando invasões em seus terrenos,
                esses moradores vivem sem a estrutura de apoio da comunidade, já
                que as edificações comunitárias foram destruídas e a maioria da
                população obrigada a abandonar o território.
              </p>
              <p>
                A comunidade de Paracatu era caminho para outras vizinhas como
                Pedras e Campinas, Águas Claras e Furquim. A passagem pela
                localidade era de grande relevância para as relações comerciais
                e assim a economia de Paracatu era fomentada pelos contatos
                frequentes com produtores vizinhos que por ali transitavam. O
                local estratégico onde se localizava, destruído pela ação
                violenta da lama de rejeitos, deixou de fazer parte dos
                moradores e produtores do subdistrito, que serão reassentados em
                outra área, próxima ao distrito de Furquim, desta vez não mais
                caracterizado como ponto de passagem.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-space-around align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_08.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_09.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_10.png"
            alt=""
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_12.png"
            alt=""
          />
        </v-col>
        <p style="font-size: 0.5em">
          Registros fotográficos da festa e procissão em honra ao padroeiro
          Santo Antônio na comunidade destruída. Fotos: Flora Passos, 2019.
        </p>
      </v-row>
    </section>

    <section class="container mb">
      <v-row class="d-flex justify-space-around align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_13.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_14.png"
            alt=""
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_15.png"
            alt=""
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            height="100%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_16.png"
            alt=""
          />
        </v-col>
        <p style="font-size: 0.5em">
          Edificações atingidas pela lama de rejeitos, incluindo o tradicional
          Bar do Jairo e a creche municipal. Fotos: Flora Passos, 2018 e 2019.
        </p>
      </v-row>
    </section>

    <section class="mb">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Cultura e Patrimônio"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade' }"
                  >Folia de Reis</router-link
                >
                ocorria de 26 de dezembro a 6 de janeiro (dia de Santos Reis), e
                após o rompimento da barragem de Fundão passou a acontecer de
                maneira adaptada, já que o território onde era realizada foi
                destruído pela ação do rejeito. O grupo tocava e seguia pelas
                ruas da própria comunidade também em outras épocas do ano. As
                famílias recebiam o grupo em casa, eles tocavam, bebiam café,
                recebiam a “esmola” e seguiam pela comunidade tocando de casa em
                casa. Muitos moradores acompanhavam a Folia e seguiam em cortejo
                para as comunidades vizinhas.
              </p>
              <p>
                Conforme relato da Dona Maria Geralda, moradora da comunidade,
                além da Folia de Reis, acontecia a Festa de São Sebastião, com
                novena, procissão, levantamento de mastro e missa. Também a
                Festa de São José, com missa, procissão e levantamento de
                mastro. A coroação de Nossa Senhora era uma festa mais
                grandiosa, com coroação das crianças, das jovens, das mães, em
                que se rezava o mês de maio todo, havendo novena, levantamento
                de mastro e procissão da bandeira. Essas festividades, no
                entanto, foram interrompidas com o rompimento da barragem,
                “ficaram pra trás”, de acordo com a Dona Maria Geralda. A Festa
                de Santa Luzia também foi interrompida, e antes havia procissão
                da bandeira, levantamento de mastro, novena e a missa. Ela
                conseguiu manter de alguma forma vigente a Festa do padroeiro
                Santo Antônio, do Menino Jesus e Nossa Senhora Aparecida, com
                continuação, na verdade, somente a carreata. Antes do
                rompimento, era celebrada a missa no dia da Festa e, em outro
                dia, a carreata. E havia a participação dos moradores de
                Paracatu de Cima que vinham até Paracatu de Baixo participar das
                celebrações e festividades.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_17A.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Bar do Jairo antes do rompimento. Foto: Fotos: Acervo do Núcleo,
            data não identificada.
          </p>
        </v-col>
      </v-row>
    </section>

    <section section class="mb">
      <v-row class="d-flex justify-center align-center">
        <v-col class="col-12 col-md-6 d-flex justify-end">
          <v-img
            max-width="70%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_17.png"
            alt=""
          />
        </v-col>
        <v-col class="col-12 col-md-6">
          <v-img
            max-width="70%"
            class="ma-2"
            src="../../assets/sobre_territorio_paracatu_baixo_18.png"
            alt=""
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <p style="font-size: 0.6em; text-align: center">
          Vista interna e externa da Igreja de Santo Antônio, atingida pela
          lama. Foto: Flora Passos, 2019.
        </p>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Igreja de Santo Antônio"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Construída em estilo colonial na década de 1990 em substituição
                à igreja centenária, que se encontrava em mau estado de
                conservação, sendo conservados o retábulo, o sino e as imagens
                sacras que são procedentes da antiga edificação datadas do
                século XIX. A igreja foi drasticamente atingida pela lama de
                rejeitos de mineração que chegou a uma altura aproximada de 4,5
                metros, entretanto resistiu apesar dos danos nas esquadrias e
                alvenarias.
              </p>
              <p>
                A Igreja de Santo Antônio foi implantada em terreno plano,
                justamente na região de planície da comunidade. Apresenta
                volumetria prismática de grandes proporções, com planta
                retangular e pé-direito duplo. A fachada frontal plana e
                simétrica é ladeada por duas torres contendo, cada uma, duas
                janelas na altura do coro e na altura dos sinos alinhadas
                verticalmente. Esses vãos possuem verga com arco abatido e
                folhas de fechamento em madeira. As torres são encimadas por
                cobertura de telhas cerâmicas do tipo francesas disposta em
                quatro águas, arrematadas por uma cruz. A fachada é composta
                ainda por uma ampla porta de madeira centralizada, tendo acima
                um óculo na altura do coro. O telhado da nave é disposto em duas
                águas, com cobertura de telhas cerâmicas sobre estrutura
                metálica. Sobre a cumeeira próxima à empena frontal, há uma cruz
                semelhante àquelas situadas acima das torres. O templo possui
                sistema construtivo com estrutura de concreto armado e vedação
                em blocos de concreto, rebocados e com pintura. O piso é
                cerâmico e o forro com réguas de P.V.C. O altar de madeira, que
                foi danificado com a ação do rejeito, assim como o sino foram
                retirados do local e conduzidos para a Reserva Técnica da
                Fundação Renova. O adro é fechado por mureta de alvenaria, e
                possuía um portão metálico na porção frontal.
              </p>
              <p>
                A igreja foi atingida pela lama de rejeitos e sofreu sérios
                danos, restando aprumadas as alvenarias e parte de outros
                elementos construtivos. No templo atingido pela correnteza de
                rejeitos, até hoje se mantém a marca da lama, preservada pelos
                moradores para que o desastre–crime não seja esquecido.
              </p>

              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_paracatu_baixo_19.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Igreja de Paracatu de Baixo, atingida pela lama de rejeitos. Foto:
            Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
.mb {
  margin-bottom: 2em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
